// create course constants
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_FAILED = "GET_QUESTIONS_FAILED";

// create course constants
export const GET_QUESTIONS_BY_ID = "GET_QUESTIONS_BY_ID";
export const GET_QUESTIONS_BY_ID_SUCCESS = "GET_QUESTIONS_BY_ID_SUCCESS";
export const GET_QUESTIONS_BY_ID_FAILED = "GET_QUESTIONS_BY_ID_FAILED";

// create course constants
export const CREATE_QUESTIONS = "CREATE_QUESTIONS";
export const CREATE_QUESTIONS_SUCCESS = "CREATE_QUESTIONS_SUCCESS";
export const CREATE_QUESTIONS_FAILED = "CREATE_QUESTIONS_FAILED";

// create course constants
export const UPDATE_QUESTIONS = "UPDATE_QUESTIONS";
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILED = "UPDATE_QUESTIONS_FAILED";

// delete course constants
export const DELETE_QUESTIONS = "DELETE_QUESTIONS";
export const DELETE_QUESTIONS_SUCCESS = "DELETE_QUESTIONS_SUCCESS";
export const DELETE_QUESTIONS_FAILED = "DELETE_QUESTIONS_FAILED";

export const CLEAN_UP_QUESTIONS = "CLEAN_UP_QUESTIONS";
